/* WHEN DARK THEME IS THERE DATERANGEPICKER'S CSS*/

/* Note:-Use Of This Will Break The Dropdown's Ui. */
/* FOR DATERANGEPICKER'S COLOR INVERT
.dateRangePickerInverted {
  filter: invert(90%);
} */

/* FOR DATERANGEPICKER'S FUNCTNALITY WORK */
.DateRangePickerDialog .MuiBox-root.css-14wf2qq {
  display: none;
}

/* FOR INVERT DAY NAME */
.dateRangePickerInverted .css-b5ylp1-MuiGrid-root {
  background: white;
  filter: invert(90%);
}
/* FOR INVERT DAY OF MONTH */
.dateRangePickerInverted .css-7dd3dv-MuiGrid-root {
  background: white;
  filter: invert(90%);
}

/* FOR INVERT OF GENRAL BACKGROUND COLOR IN MONTH SECTION AND BORDER SECTION */
.dateRangePickerInverted .css-11lq3yg-MuiGrid-root {
  background: rgb(67 68 70 / 54%);
}

/* FOR INVERT GENRAL BACKGROUND OF CALANDER SECTION */
.dateRangePickerInverted .css-vj1n65-MuiGrid-root {
  background: #191919;
}

/* FOR FONT COLOR CHANGE OF "Start Date" & "End Date" */
.dateRangePickerInverted .css-1j13ek9-MuiGrid-root {
  filter: invert(90%);
}

/* FOR ARROW BETWEEN "Start Date" & "End Date" COLOR CHANGE */
.dateRangePickerInverted
  .css-11pbyhm-MuiSvgIcon-root
  path[d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"] {
  fill: #191919b6;
}

/* FOR BOTTOM BORDER COLOR CHANGE PLACES BELOW MONTH AND YEAR */
.dateRangePickerInverted
  .css-skvvea-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
  border-bottom: 1px solid rgb(179 179 179 / 42%);
}
/* FOR BOTTOM BORDER COLOR CHANGE PLACES BELOW MONTH AND YEAR ON HOVER */
.dateRangePickerInverted
  .css-skvvea-MuiInputBase-root-MuiInput-root-MuiSelect-root:before:hover {
  fill: rgba(41, 128, 242, 0.561);
  border-bottom: 1px solid rgba(41, 128, 242, 0.561);
}

/* FOR INVERT THE COLOR OF LEFTSIDE'S FUNCTION NAME */
.dateRangePickerInverted .css-h4y409-MuiList-root {
  filter: invert(90%);
}

/* FOR INVERT BOTTOM OF THE MONTH COLOR */
.dateRangePickerInverted .css-ttghbg-MuiPaper-root {
  background-color: #191919;
}

/* FOR LINE COLOR CHANGE OF MIDDLE */
.dateRangePickerInverted .css-1tk1bks-MuiDivider-root {
  background-color: #2a2a2a;
}

/* FOR lEFTMOST AND RIGHTMOST SIDE ARRORW COLOR CHANGE */
.dateRangePickerInverted .css-11pbyhm-MuiSvgIcon-root {
  fill: #efefefbf;
}

/* FOR RIGHTINNER AND LEFTINNER SIDE ARRORW COLOR CHANGE */
.dateRangePickerInverted .css-1db085k-MuiSvgIcon-root {
  fill: #efefefbf;
}

/* FOR MONTH AND YEAR FONT COLOR CHANGE */
.dateRangePickerInverted
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  color: #efefef99;
}

/* DROPDOWN OPEN ICON COLOR CHANGE */
.dateRangePickerInverted .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: #efefef99;
}

/* DROPDOWN CLOSE ICON COLOR CHANGE */
.dateRangePickerInverted .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  color: #efefef99;
}

/* DROPDOWN BACKGROUND AND FONT COLOR CHANGE */
.dateRangePickerInverted
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #191919;
  color: #efefef99;
  box-shadow: 0px 0px 4px 3px rgb(92 89 89 / 20%),
    0px 0px 0px 3px rgb(104 99 99 / 14%), 0px 0px 6px 3px rgb(122 120 120 / 12%);
}

/* FOR BOX SHADOW(BACK) OF DATERANGEPICKER COMPONENT */
.dateRangePickerInverted .css-1tehbvn-MuiPaper-root {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(205, 204, 204, 0.12);
}

/* FOR ALL LIST ITEAMS */ /* EXCEPT THEN "This Month" & "This Year" IF DateRangePickerDialog.JS HAS maxDate={currentDate} UNCOMMENTED */
.dateRangePickerInverted .css-1ydptia-MuiButtonBase-root-MuiListItem-root {
  background: #ea9a3f;
  color: inherit;
}

/* FOR ALL LIST ITEAMS onHover*/
.dateRangePickerInverted
  .css-1ydptia-MuiButtonBase-root-MuiListItem-root:hover {
  background: #ea9a3f;
}
/* FOR THE STARTING OR ENDING POINT OF THE RANGE WITHOUT TODAY'S DATE */
.dateRangePickerInverted .css-xpa0r5-MuiButtonBase-root-MuiIconButton-root {
  background: #ea9a3f;
}

/* FOR THE STARTING OR ENDING POINT OF THE RANGE WITHOUT TODAY'S DATE onHover */
.dateRangePickerInverted
  .css-xpa0r5-MuiButtonBase-root-MuiIconButton-root:hover {
  background: #ea9a3f;
}

/* FOR THE STARTING OR ENDING POINT OF THE RANGE WITH TODAY'S DATE */
.dateRangePickerInverted .css-1vwi480-MuiButtonBase-root-MuiIconButton-root {
  background: #ea9a3f;
}
/* FOR THE STARTING OR ENDING POINT OF THE RANGE WITH TODAY'S DATE onHover */
.dateRangePickerInverted
  .css-1vwi480-MuiButtonBase-root-MuiIconButton-root:hover {
  background: #ea9a3f;
}

/* FOR CURRENT DATE SELECT'S BORDER COLOR WHEN SATRT OR END SELECTED RANGE IS NOT ON CURRENT DATE */
.dateRangePickerInverted .css-1d8v355-MuiButtonBase-root-MuiIconButton-root {
  border-color: #ea9a3f;
}

/* FOR CURRENT DATE SELECT'S BORDER COLOR WHEN SATRT OR END SELECTED RANGE IS ON CURRENT DATE */
.dateRangePickerInverted .css-1vwi480-MuiButtonBase-root-MuiIconButton-root {
  border-color: #ea9a3f;
}

/* FOR ALL DATE BETWEEN SELECTED DATE BACKGROUND */
.dateRangePickerInverted .css-1xisk3n {
  background: #e7771c77;
}

/* FOR START DATE SELECTED HALF BACKGROUND */
.dateRangePickerInverted .css-bc1jo5 {
  background: #e7771c77;
}

/* FOR END DATE SELECTED HALF BACKGROUND */
.dateRangePickerInverted .css-k0gbvj {
  background: #e7771c77;
}
.css-144fuy4-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  border-radius: 5px 5px 0px 0px;
}
.css-1qkil1h-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.css-144fuy4-MuiButtonBase-root-MuiAccordionSummary-root.Mui-disabled {
  border-radius: 5px;
}
