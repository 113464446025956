/* Add this CSS to your stylesheet */
.react-calendar .react-calendar__tile.event-date abbr {
  display: none;
}

.react-calendar .react-calendar__tile.public-holiday abbr {
  display: none;
}

.react-calendar .react-calendar__tile.std-date abbr {
  display: none;
}

.react-calendar__tile--active {
  background-color: #2c7ce532 !important;
}

.react-calendar {
  background: var(--event-calendar-color) !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14) inset !important;
  padding: 9px;
  border: 0px;
  border-radius: 5px;
}

.react-calendar__navigation__arrow {
  color: var(--primary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__navigation__label {
  background: var(--event-calendar-color) !important;
  color: var(--primary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__month-view__weekdays {
  color: var(--primary-text-color) !important;
}

.react-calendar__month-view__days__day {
  color: var(--secondary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__month-view__days__day--weekend abbr {
  color: var(--reject-color) !important;
  border-radius: 5px;
}

.react-calendar__tile--now {
  color: #cf8c07 !important;
  font-weight: bold !important;
  background-color: #7d7d7d23;
  border-radius: 5px;
}

.react-calendar__year-view__months__month {
  color: var(--secondary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__decade-view__years__year {
  color: var(--secondary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__century-view__decades__decade {
  color: var(--secondary-text-color) !important;
  border-radius: 5px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #2c7ce532 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #2c7ce532 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #2c7ce532 !important;
}

.react-calendar__navigation button:disabled {
  background-color: #2c7ce532 !important;
}
