.MuiDataGrid-root {
  color: var(--secondary-text-color);

  .MuiSelect-icon {
    fill: var(--secondary-text-color);
  }

  ;

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-input,
  .MuiTablePagination-displayedRows {
    color: var(--secondary-text-color) !important;
  }

  .MuiDataGrid-cellContent {
    color: var(--secondary-text-color) !important;
    font-size: 14px;
  }

  ;

  .MuiDataGrid-columnHeader {
    color: var(--secondary-text-color) !important;
    font-size: 18px;
    /* background-color: var(--primary-highlight-color) !important; */
    width: 100vh;
  }

  ;

  .MuiDataGrid-footerContainer {
    color: var(--secondary-text-color) !important;
  }

  ;

  .MuiDataGrid-menuIconButton {
    color: var(--secondary-text-color) !important;
  }

  ;

  .MuiDataGrid-sortIcon {
    color: var(--secondary-text-color) !important;
    visibility: true !important;
  }

  ;

  .MuiDataGrid-columnHeader:focus {
    outline-color: rgb(25, 118, 210);
    outline-style: solid;
    outline-width: 0px;
  }

  ;

  .MuiDataGrid-cell:focus {
    outline-color: rgb(25, 118, 210);
    outline-style: solid;
    outline-width: 0px;
  }

  ;
}

;