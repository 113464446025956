.MuiDateCalendar-root {
	.MuiPickersCalendarHeader-label {
		color: var(--secondary-text-color) !important;
		font-weight: bold !important;
	}
	.MuiPickersCalendarHeader-switchViewIcon {
		color: var(--secondary-text-color) !important;
	}
	.MuiPickersDay-root {
		color: var(--secondary-text-color) !important;
		.MuiPickersDay-root:not(.Mui-selected) {
			color: #fff !important;
			border: 1px solid var(--primary-color) !important;
			background-color: var(--primary-color) !important;
		}
	}
	.MuiSvgIcon-root {
		color: var(--secondary-text-color) !important;
	}
	.MuiDayCalendar-weekDayLabel {
		color: var(--secondary-color) !important;
		font-weight: bold !important;
	}
	.MuiPickersYear-yearButton {
		color: var(--secondary-text-color) !important;
	}
	.Mui-selected {
		color: #fff !important;
		border: 1px solid var(--secondary-text-color) !important;
	}
	.MuiDateCalendar-root {
		overflow: auto !important;
	}
	@media (max-width: 600px) {
		flex-direction: column;
		& > div {
			width: 100%;
		}
	}
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
	border: 1px solid var(--primary-color) !important;
}
