.login-image {
  max-width: 500px;
  display: flex;
  justify-content: center,
}
.admin-login {
  .admin-images {
    background-color: #f9f9f9;

    .logo-img {
      width: 180px;
    }

    .login-img {
      width: 500px;
    }
  }

  .admin-form {
    .link {
      color: var(--primary-color);
      font-weight: bold;
      text-decoration: none
    }
  }
}
