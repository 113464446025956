.sidebar-menu {
  margin-top: 2px;
  font-size: 15px;
  color: var(--secondary-text-color);
  width: 100%;
  border-radius: 5px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;

  &:hover {
    background: var(--primary-highlight-color);
    color: var(--primary-color);
    font-weight: bold;
  }

  svg {
    padding-right: 10px;
  }
}

.sidebar-menu-active {
  margin-top: 2px;
  font-size: 15px;
  color: var(--primary-color);
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;

  &:hover {
    background: var(--primary-highlight-color);
    color: var(--primary-color);
    font-weight: bold;
  }

  background: var(--primary-highlight-color);

  svg {
    padding-right: 10px;
  }
}

.sidebar-menu-hide {
  margin-top: 5px;
  font-size: 15px;
  color: var(--secondary-text-color);
  width: 100%;
  border-radius: 5px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;

  &:hover {
    background: var(--primary-highlight-color);
    color: var(--primary-color);
    font-weight: bold;
  }

  svg {
    padding-right: 10px;
  }
}

.sidebar-menu-hide-active {
  margin-top: 5px;
  font-size: 15px;
  color: var(--primary-color);
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;

  &:hover {
    background: var(--primary-highlight-color);
    color: var(--primary-color);
    font-weight: bold;
  }

  background: var(--primary-highlight-color);

  svg {
    padding-right: 10px;
  }
}

.sidebar-accordion {
  margin-left: 1px;
  display: flex;
}

.sidebar-accordion-hide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

