:root .dark-theme {
  --website-background-color: #0b1727;
  background-color: var(--website-background-color);

  --invert-icon-color: #e2e2e2;
  --primary-color: rgb(44, 123, 229);
  --secondary-color: rgb(156, 195, 28);
  --secondary-color-H: rgb(137, 171, 28);
  --secondary-highlight-color: rgb(155, 195, 28, 0.1);
  --primary-background-color: rgba(44, 124, 229, 0.06);
  --background-table-sidebar-card-color: #121e2e;
  --background-table-borderHeader-color: #121e2ebb;
  --event-calendar-color: #1a283b;
  --primary-highlight-color: #142840;
  --primary-text-color: #e0e0e0e3;
  --secondary-text-color: #e0e0e0cc;
  --secondary-text-color-H: #bfbfbfcc;
  --selected-checkbox: #f6f6f624;
  --tag-background-color: #1d2d42;
  --tag-close-icon-color: #a2a0a0;
  --search-bar-color: #142840;
  --table-border-color: #19314e;
  --pending-color: #004fff99;
  --approved-color: #97be1dd6;
  --reject-color: #ff4d4d;
  --reject-highlight-color: #faaaaa1c;
  --cancel-color: #7d7d7d;
  --totalHrs-color: #f4b73e;
  --totalHrs-highlight-color: #ffbe3b24;
  --start-color: green;
  --avgWorkingHrs-color: #8e3ef4;
  --avgWorkingHrs-highlight-color: #8e3ef42b;
  --taskRemain-highlight-color: #fcf33d9b;
  --taskComplete-highlight-color: #3a953eeb;
  --taskOverdue-highlight-color: #c4501ae4;
  --taskinProgress-highlight-color: #af7bf3bf;
  --accordian-background-color: #121e2e;
  --subTaskdataGrid-background-color: #142840f4;
  --subAccordian-background-color: #19314ed8;
  --early-bird-point-color: #50b21f;
  --late-owl-point-color: #dc1f1f;
  --tooltip-color: rgb(255, 255, 255);
  --textProfile-background-color: #72910b;
  --app-dark: #0b1727;
}

:root {
  --website-background-color: #ffffff;
  background-color: var(--website-background-color);

  --invert-icon-color: #101010;
  --primary-color: rgb(44, 123, 229);
  --secondary-color: rgb(156, 195, 28);
  --secondary-color-H: rgb(137, 171, 28);
  --secondary-highlight-color: rgb(155, 195, 28, 0.1);
  --primary-background-color: rgba(44, 124, 229, 0.06);
  --background-table-sidebar-card-color: #ffffff;
  --event-calendar-color: #ffffff;
  --background-table-borderHeader-color: #2c7be51a;
  --primary-highlight-color: #2c7be51a;
  --primary-text-color: #544f5a;
  --secondary-text-color: #5e6e82;
  --secondary-text-color-H: #526172;
  --selected-checkbox: #cdcdcdd8;
  --tag-background-color: #b2b0b5ba;
  --tag-close-icon-color: #4b4747;
  --search-bar-color: #ffffff;
  --table-border-color: #e5e7eb;
  --pending-color: #004fff99;
  --approved-color: #97be1dd6;
  --reject-color: #ff4d4d;
  --reject-highlight-color: #faaaaa1c;
  --cancel-color: #7d7d7d;
  --totalHrs-color: #f4b73e;
  --totalHrs-highlight-color: #ffbe3b24;
  --start-color: green;
  --avgWorkingHrs-color: #8e3ef4;
  --avgWorkingHrs-highlight-color: #8e3ef42b;
  --taskComplete-highlight-color: #55bb5ae5;
  --taskRemain-highlight-color: #ecdc34d2;
  --taskOverdue-highlight-color: #d06a3bc9;
  --taskComplete-highlight-color: #48a14d;
  --taskRemain-highlight-color: #5f5b5b;
  --accordian-background-color: #ffffff;
  --subAccordian-background-color: #ffffff;
  --subTaskdataGrid-background-color: #ffffff;
  --taskOverdue-highlight-color: #d06a3b;
  --taskinProgress-highlight-color: #af7bf3bf;
  --early-bird-point-color: #60e21f;
  --late-owl-point-color: #dc1f1f;
  --tooltip-color: #d5d2d7;
  --textProfile-background-color: rgba(156, 195, 28, 0.863);

  --darkTheme-before-login-bg: #0b1727;
  --lightTheme-before-login-bg: #ffffff;

  --darkTheme-before-login-sideblock-bg: #14273f;
  --darkTheme-before-login-img-bg: #121e2e;
  --lightTheme-before-login-img-bg: #2c7be51a;

  --darkTheme-inputfield-color: #e0e0e0e3;
  --lightTheme-inputfield-color: #544f5a;
  --app-dark: #ffffff;
}

/* DataGrid column visibility */
.css-2qz4sq-MuiPaper-root-MuiDataGrid-paper {
  max-height: 250px !important;
  overflow-y: auto !important;
  min-width: 250px !important;
}

.css-nchus6-MuiDataGrid-columnsManagementHeader {
  padding: 12px 14px !important;
}

/* Hide checkbox for rows that are completed */
.completed-row .MuiCheckbox-root {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

svg {
  fill: var(--secondary-text-color);
  stroke-width: 0.5px;
  height: 41px;
}

.color-picker {
  position: absolute;
  margin-top: 5px;
  z-index: 9999;
  right: 0;
}

.custom-color-input {
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
}

.custom-color-input input {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: transparent;
}

.custom-color-input .MuiColorInput-colorPreview {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
}

.custom-color-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  padding: 0;
  margin: 0;
  border: none;
}

.css-jery6c {
  width: "200px"
}

.App {
  background-color: var(--app-dark);
}

.ql-snow svg {
  height: 19px !important;
}

.ReactQuillDark .ql-snow .ql-fill {
  fill: #d5d2d7 !important;
}

.ReactQuillDark .ql-snow .ql-fill:hover {
  fill: #06c !important;
}

.ReactQuillDark .ql-snow .ql-picker {
  color: #d5d2d7;
}

.ReactQuillDark .ql-snow .ql-stroke {
  stroke: #d5d2d7;
}

.ReactQuillDark .ql-editor.ql-blank::before {
  color: #d5d2d7;
}

.ql-editor {
  min-height: 100px;
}

.ql-container.ql-snow {
  border: 1px solid var(--secondary-text-color) !important;
  border-top: none !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--secondary-text-color) !important;
}

.ReactQuillDark .ql-toolbar.ql-snow {
  border: 1px solid #d6d4d4 !important
}

.ReactQuillDark .ql-container.ql-snow{
  border: 1px solid #d6d4d4 !important;
  border-top: none !important;
}

.ReactQuillDark .ql-editor {
  min-height: 100px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(84, 79, 90, 0.5);
  border-radius: 10px;
}

td,
th {
  border-bottom: 1px solid var(--table-border-color) !important;
}

.MuiSelect-icon {
  color: var(--primary-text-color) !important;
}

.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: var(--secondary-text-color) !important;
}

.MuiOutlinedInput-input {
  color: var(--secondary-text-color) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::-webkit-calendar-picker-indicator {
  color: var(--secondary-text-color) !important;
}

.MuiFormLabel-root {
  color: var(--secondary-text-color) !important;
}

.css-y660a4-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--secondary-text-color) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--secondary-text-color) !important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--secondary-text-color) !important;
} */
/* y-MuiFormLabel-root-MuiInputLabel-root */
.custom-text-group {
  /* Add your custom styles for the 'g' element here */
  fill: var(--secondary-text-color) !important;
  /* Change the fill color as needed */
  /* Add other styles as needed */
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
}

/* .css-9sgtv1-MuiPaper-root-MuiAccordion-root::before {
  top: 0px !important;
} */

.MuiAccordion-root::before {
  top: 0px !important;
  display: none;
}

/* JYOTI TECHNOSOFT LLP LOGO'S tECHNOSOFT LLP'S DESIGN */
.brandLogoDarkThemeclass>g>path:nth-child(2) {
  fill: lightgray !important;
}

/* FOR THE EMPLOYEE TASK TABLE THAT IS AVAILABLE IN THE DASHBOARD */
.empTaskTable .MuiTablePagination-selectLabel {
  display: none;
}

.empTaskTable .MuiInputBase-root {
  display: none;
}

.empTaskTable .MuiTablePagination-displayedRows {
  display: none;
}

/* SIDEBAR BUTTONS ON HOVER GRAY COLOR FIX */
.css-1j72ahl-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: transparent !important;
}

/* SIDEBAR BUTTONS ON CLICK GRAY COLOR FIX */
.MuiTouchRipple-root {
  display: none !important;
}

/* .tooltip {
  position: absolute;
  text-align: start;
  padding: 8px;
  font: 12px sans-serif;
  color: var(--secondary-text-color) !important;
  background: var(--tooltip-color) !important;
  border-radius: 5px;
  pointer-events: none;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper {
  /* background-color: transparent !important; */
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
  color: var(--secondary-text-color) !important;
}

.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-colorPrimary.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  display: none;
}

.MuiAutocomplete-endAdornment svg {
  color: var(--primary-text-color) !important;
}