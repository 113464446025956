.tb-preloader-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}

.tb-preloader {
  /* position: absolute;
  /* top: 50%;
  left: 60%; */
  /* transform: translate(-50%, -50%);  */
}

.tb-preloader .tb-preloader-in {
  /* position: relative; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 70px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #0e7cbf;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1.5s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container img {
  width: 35px;
  height: auto;
}

/* Old Loader */
/* .loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #0e7cbf;
  border-right-color: #0e7cbf;
  animation: rotateLoader 2s linear infinite;
} */

/* @keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
