.MuiAccordionSummary-gutters {
  border-radius: 5px !important;
}

.Mui-expanded.MuiAccordionSummary-gutters {
  border-radius: 5px 5px 0px 0px !important;
}

.MuiAccordionDetails-root {
  border-radius: 0px 0px 5px 5px !important;
}

.MuiAccordion-gutters::before {
  display: none !important;
}

.MuiAccordion-gutters {
  box-shadow: none !important;
}

.MuiPaper-elevation1 .MuiAccordion-root {
  background-color: transparent !important;
}

.MuiAccordionSummary-gutters {
  border-radius: 5px !important;
}

.css-15cc86n-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

.css-151x8oa-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

.css-9sgtv1-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

.MuiAccordion-root {
  background: transparent !important;
}

.MuiAccordionSummary-expandIconWrapper svg {
  fill: var(--primary-text-color) !important;
}
