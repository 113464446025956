.admin-signup {
    .link {
      color: var(--primary-color);
      font-weight: bold;
      text-decoration: initial;
    }
    .signup-image {
      width: 100%;
      display: flex;
      justify-content: center;
    }
}
